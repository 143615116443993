<template>
	<div style="height: 100vh" class="m-5">
		<div v-if="value" class="columns is-centered is-vcentered">
			<div class="column is-4-desktop is-6-tablet is-full-mobile">
				<div class="card">
					<div
						class="card-header"
						style="
							background: linear-gradient(
								89.31deg,
								#211895 1.38%,
								#e64bff 97.6%
							);
						">
						<div
							class="card-header-title is-flex is-justify-content-space-between is-align-items-center">
							<img width="150" :src="`/logos/${site}/logo.svg`" />
							<img
								width="150"
								:src="`/logos/${site}/papara.svg`" />
						</div>
					</div>
					<div class="card-content is-centered p-3">
						<b-field label="Alıcı Papara İsmi">
							<b-input
								v-model="transaction.bankAccount.name"
								disabled
								expanded
								id="bankAccountName" />
							<p class="control">
								<b-button
									type="is-success"
									icon-left="clipboard-check-multiple"
									@click="copy('bankAccountName')"
									>Kopyala</b-button
								>
							</p>
						</b-field>
						<hr />
						<b-field label="Alıcı Papara Numarası">
							<b-input
								v-model="transaction.bankAccount.iban"
								disabled
								expanded
								id="bankAccount" />
							<p class="control">
								<b-button
									type="is-success"
									icon-left="clipboard-check-multiple"
									@click="copy('bankAccount')"
									>Kopyala</b-button
								>
							</p>
						</b-field>
						<hr />
						<p class="has-text-centered">
							QR Kodu ile hızlı ödeme yapabilirsiniz.
						</p>
						<hr />
						<qrcode-vue
							:value="value"
							:size="size"
							level="H"
							style="text-align: center"
							class="qrcode" />
						<hr />
						<p class="has-text-centered">
							Papara'dan ödemenizi yaptıktan sonra, <br />
							"<b><i>Ödemeyi Gerçekleştirdim</i></b
							>" butonuna tıklayıp işleminizi bildirin.
						</p>
						<hr />
						<div class="is-flex is-justify-content-center">
							<b-button
								class="mr-3"
								type="is-danger"
								:loading="loading"
								@click="cancel"
								>İptal Et</b-button
							>
							<b-button
								type="is-success"
								expanded
								:loading="loading"
								@click="pay"
								>Ödemeyi Gerçekleştirdim ({{
									counter
								}})</b-button
							>
						</div>
					</div>
				</div>
			</div>
		</div>
		<div v-else>İşlem Bulunamadı</div>
	</div>
</template>

<script>
	import { TransactionService } from "@/services"
	import QrcodeVue from "qrcode.vue"

	export default {
		name: "PaparaPay",
		components: { QrcodeVue },
		data() {
			return {
				transaction: {},
				value: null,
				size: 300,
				loading: false,
				counter: 3,
			}
		},
		mounted() {
			const html = document.getElementsByTagName("html")[0]
			html.classList = []
			this.init()
		},
		methods: {
			init() {
				TransactionService.check(this.$route.params.id)
					.then(({ data }) => {
						if (data.status !== "PENDING") {
							window.location.href = data.failRedirectUrl
						} else {
							this.transaction = data
							this.value =
								"https://www.papara.com/personal/qr?accountNumber=" +
								data.bankAccount.iban +
								"&currency=0&amount=" +
								data.total
						}
					})
					.catch((err) => {
						this.$buefy.toast.open({
							message: err.message,
							position: "is-top-right",
							type: "is-danger",
						})
						setTimeout(() => {
							// this.$router.go(-1)
						}, 3000)
					})
			},
			pay() {
				this.counter--
				this.loading = true
				TransactionService.pay({
					transactionId: this.transaction.uuid,
				})
					.then((res) => {
						if (res.data.redirectUrl)
							window.location.href = res.data.redirectUrl
						else this.$router.push({ name: "paymentSuccess" })
					})
					.catch((err) => {
						this.$buefy.toast.open({
							message:
								err.message +
								" Lütfen yatırımınızı kontrol edip tekrar deneyin.",
							position: "is-bottom",
							type: "is-warning",
							duration: 8000,
						})
						if (err.redirectUrl) {
							setTimeout(() => {
								if (this.counter < 1)
									window.location.href = err.redirectUrl
							}, 1000)
						} else {
							this.$router.push({ name: "paymentError" })
						}
					})
					.finally(() => (this.loading = false))
			},
			copy(val) {
				const copyText = document.getElementById(val)
				copyText.select()
				copyText.setSelectionRange(0, 99999) /* For mobile devices */
				navigator.clipboard.writeText(copyText.value)
				this.$buefy.toast.open({
					duration: 3000,
					message: "Kopyalandı!",
					position: "is-bottom",
					type: "is-success",
				})
			},
			cancel() {
				this.loading = true
				TransactionService.cancel({
					transactionId: this.transaction.uuid,
				})
					.then((res) => {
						this.$buefy.toast.open({
							message: res.data.message,
							position: "is-top-right",
							type: "is-success",
							duration: 2000,
						})
						setTimeout(() => {
							window.location.href = res.data.redirectUrl
						}, 2000)
					})
					.catch((err) =>
						this.$buefy.toast.open({
							message: err.message,
							position: "is-bottom",
							type: "is-info",
						})
					)
					.finally(() => (this.loading = false))
			},
		},
	}
</script>

<style>
	.qrcode > canvas {
		border: 6px solid #ddd;
		padding: 0.5rem;
		border-radius: 12px;
	}

	.input[type="text"]::placeholder {
		color: #000000 !important;
	}

	.input[type="text"] {
		color: #000000 !important;
	}
	hr {
		margin: 0.5rem;
	}
</style>
